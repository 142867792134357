<template>
  <div class="keyword-exclude">
    <SettingHeader>Keyword & Exclude</SettingHeader>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane
        key="1"
        tab="Keyword"
        :forceRender="true"
      >
        <MessageAlertNewSetup :key="refreshComponents"></MessageAlertNewSetup>
        <KeywordInputBlock
          :hasTitle="true"
          :current-word-list="currentKeywordList"
          :onAdd="onAddKeyword"
        />
        <a-card class="word-list-wrapper keyword-table">
          <a-skeleton
            v-if="loading"
            :loading="loading"
            active
          />
          <TableFilterWrapper
            v-else
            title="Keyword List"
            :filterOption="filterOption"
            :rowKey="'originalKeyword'"
            :isRowSelection="true"
            :isTimeFromNow="true"
            :isTypeTag="true"
            :tableData="currentKeywordList"
            :columns_props="columnConfigSelected('keyword')"
            :actions="actions"
            :pagination="{
              showQuickJumper: false,
              showSizeChanger: true,
              total: currentKeywordList.length,
              showTotal: () => '',
            }"
            @toggleAction="onToggleAction"
            @change="onKeywordChange"
          />
        </a-card>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="Exclude Keyword"
        :forceRender="true"
      >
        <MessageAlertNewSetup></MessageAlertNewSetup>
        <KeywordInputBlock
          :hasTitle="true"
          :title="'Enter Exclude Keyword'"
          :current-word-list="currentExcludeKeywordList"
          @add="onAddExcludeKeyword"
        />
        <a-card class="word-list-wrapper exclude-table">
          <TableFilterWrapper
            title="Exclude Keyword List"
            :filterOption="filterOption"
            :rowKey="'originalKeyword'"
            :isRowSelection="true"
            :isTimeFromNow="true"
            :isTypeTag="true"
            :tableData="currentExcludeKeywordList"
            :columns_props="columnConfigSelected('exclude')"
            :actions="actions"
            :pagination="{
              showQuickJumper: false,
              showSizeChanger: true,
              total: currentExcludeKeywordList.length,
              showTotal: () => '',
            }"
            @toggleAction="onToggleExAction"
            @change="onExKeywordChange"
          />
        </a-card>
      </a-tab-pane>
    </a-tabs>
    <ConfirmDelete
      :closable="true"
      :visible="deleteVisible"
      @close="deleteVisible = false"
      @confirm="onConfirmDelete"
    >
      <span v-if="deletingItem.isMultiple">
        Are you sure to delete these
        {{ deletingItem.keywordList.length }} keywords ?
      </span>
      <span v-else> Are you sure to delete this keyword ? </span>
    </ConfirmDelete>
    <a-modal
      title="Edit Keyword"
      :visible="editVisible"
      :width="520"
      :closable="false"
      @cancel="editVisible = false"
    >
      <div class="edit-modal-content">
        <div class="form-row mb-4">
          <div class="form-title">Type</div>
          <div
            id="keyword-type-selector"
            class="form-input"
          >
            <a-select
              v-model:value="editInput.type"
              :getPopupContainer="(trigger) => trigger.parentNode"
              class="type-selector w-100"
            >
              <a-select-option value="word">Word</a-select-option>
              <a-select-option value="username">Username</a-select-option>
              <a-select-option value="hashtag">Hashtag</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-title">Keyword</div>
          <div class="form-input">
            <a-input
              v-model:value="editInput.editingKeyword"
              placeholder="Enter keyword"
            ></a-input>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="edit-modal-action text-right">
          <a-button
            size="large"
            type="default"
            class="cancel-button mr-2"
            :outlined="true"
            @click="editVisible = false"
          >
            Cancel
          </a-button>
          <a-button
            size="large"
            type="primary"
            class="confirm-button"
            @click="confirmEdit"
            >Save Changes</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue';
import { notification } from 'ant-design-vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import SettingHeader from './SettingHeader.vue';
import KeywordInputBlock from './KeywordInputBlock.vue';
import TableFilterWrapper from '@/components/Table/TableFilterWrapper.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';
import MessageAlertNewSetup from '@/components/Message/MessageAlertNewSetup.vue';

export default {
  components: {
    TableFilterWrapper,
    SettingHeader,
    KeywordInputBlock,
    ConfirmDelete,
    MessageAlertNewSetup,
  },
  setup() {
    const store = useStore();
    const brand = computed(() => store.state.account.brand);

    const refreshComponents = ref(0);
    const refreshComponentsFunc = () => {
      refreshComponents.value += 1;
    };
    const activeKey = ref('1');
    const loading = ref(true);
    const deleteVisible = ref(false);
    const editVisible = ref(false);
    const deletingItem = reactive({
      keyword: '',
      mode: '',
      isMultiple: false,
      keywordList: [],
    });
    const editInput = reactive({
      originalKeyword: '',
      keyword: '',
      type: '',
      mode: '',
    });

    const columnConfig = [
      {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
        sorter: helper.getTableSortFn('string', 'keyword'),
        // width: 200,
        slots: { customRender: 'keyword' },
        fixed: 'left',
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        slots: { customRender: 'keyword_count' },
        width: 100,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        slots: { customRender: 'type' },
        sorter: helper.getTableSortFn('string', 'type'),
        width: 100,
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        slots: { customRender: 'created_at' },
        sorter: helper.getTableSortFn('date-x', 'createdAt'),
        defaultSortOrder: 'descend',
        width: 150,
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
        width: 150,
      },
    ];

    const columnConfigSelected = (type) => {
      if (type === 'exclude') {
        return columnConfig.filter((col) => col.key !== 'count');
      }
      return columnConfig;
    };

    const filterOption = {
      key: 'type',
      title: 'Type:',
      defaultValue: '',
      filterValueList: [
        { value: '', title: 'All type' },
        { value: 'word', title: 'Keyword' },
        { value: 'hashtag', title: 'Hashtag' },
        { value: 'username', title: 'Username' },
      ],
    };

    const actions = [
      { name: 'Edit', value: 'edit', icon: 'edit' },
      { name: 'Delete', value: 'delete', icon: 'trash-2' },
    ];

    const currentKeywords = ref([]);
    const currentExcludeKeywords = ref([]);

    const convertKeyword = (obj) => {
      const { id: keyword, createdAt, count, isCentral } = obj;
      if (keyword.charAt(0) === '#') {
        return {
          originalKeyword: keyword,
          keyword: `#${keyword.slice(1)}`,
          editingKeyword: keyword.slice(1),
          type: 'hashtag',
          createdAt,
          count,
          isCentral,
        };
      }
      if (keyword.indexOf('username:') === 0) {
        return {
          originalKeyword: keyword,
          keyword: `@${keyword.slice(9)}`,
          editingKeyword: `${keyword.slice(9)}`,
          type: 'username',
          createdAt,
          count,
          isCentral,
        };
      }
      return {
        originalKeyword: keyword,
        keyword,
        editingKeyword: keyword,
        type: 'word',
        createdAt,
        count,
        isCentral,
      };
    };

    // const currentKeywordList = computed(() => {
    //   if (currentKeywords.value) {
    //     return currentKeywords.value.map((obj) => convertKeyword(obj));
    //   }
    //   return [];
    // });
    const currentKeywordList = ref([]);
    const currentExcludeKeywordList = ref([]);

    const currentKeywordIdList = computed(() => {
      if (currentKeywordList.value) {
        return currentKeywordList.value.map((obj) => obj.keyword);
      }
      return [];
    });

    const currentExcludeKeywordIdList = computed(() => {
      if (currentExcludeKeywordList.value) {
        return currentExcludeKeywordList.value.map((obj) => obj.keyword);
      }
      return [];
    });

    const getCurrentCount = async (keywordIdList, type) => {
      const result = await api.getKeywordStat(keywordIdList).catch(() => {});
      if (result && result.message && result.message.keywordStatList) {
        const keywordStatMap = {};
        for (let o of result.message.keywordStatList) {
          keywordStatMap[o.keyword] = o.count;
        }
        if (type === 'keyword') {
          for (let i in currentKeywordList.value) {
            const k = currentKeywordList.value[i];
            if (typeof keywordStatMap[k.originalKeyword] !== 'undefined') {
              k.count = keywordStatMap[k.originalKeyword];
            }
          }
        }
        if (type === 'exclude') {
          for (let i in currentExcludeKeywordList.value) {
            const k = currentExcludeKeywordList.value[i];
            if (typeof keywordStatMap[k.originalKeyword] !== 'undefined') {
              k.count = keywordStatMap[k.originalKeyword];
            }
          }
        }
      }
      return {};
    };

    const onKeywordChange = () => {
      setTimeout(() => {
        const elemList = document.querySelectorAll(
          '.keyword-table .keyword-col',
        );
        const keywordIdList = [];
        for (let elem of elemList) {
          keywordIdList.push(elem.dataset.originalKeyword);
        }
        getCurrentCount(keywordIdList, 'keyword');
      }, 500);
    };
    const onExKeywordChange = () => {
      setTimeout(() => {
        const elemList = document.querySelectorAll(
          '.exclude-table .keyword-col',
        );
        const keywordIdList = [];
        for (let elem of elemList) {
          keywordIdList.push(elem.dataset.originalKeyword);
        }
        getCurrentCount(keywordIdList, 'exclude');
      }, 500);
    };

    const getAccountKeyword = async () => {
      loading.value = true;
      const result = await api.getAccountKeyword().catch(() => {});
      if (result && result.message) {
        currentKeywords.value = result.message;
        currentKeywordList.value = currentKeywords.value.map((obj) =>
          convertKeyword(obj),
        );
        loading.value = false;
        onKeywordChange();
      }
    };

    const getAccountExcludeKeyword = async () => {
      loading.value = true;

      const result = await api.getAccountExcludedKeyword().catch(() => {});
      const centralRes = await api
        .getCentralExcludedKeyword(brand.value)
        .catch(() => {});

      if (result && result.message) {
        currentExcludeKeywords.value = result.message.map((keywordData) => ({
          ...keywordData,
          isCentral: false,
        }));

        if (centralRes && centralRes.message) {
          const centralResAddFlag = centralRes.message.map((keywordData) => {
            return {
              id: keywordData.keyword,
              keywordType: keywordData.type,
              createdAt: new Date(keywordData.createdAt).getTime(),
              updatedAt: new Date(keywordData.updatedAt).getTime(),
              isExacted: keywordData.isExacted,
              isCentral: true,
            };
          });
          currentExcludeKeywords.value.push(...centralResAddFlag);

          currentExcludeKeywords.value = helper.getUniqueList(
            currentExcludeKeywords.value,
          );
        }

        currentExcludeKeywordList.value = currentExcludeKeywords.value.map(
          (obj) => convertKeyword(obj),
        );

        loading.value = false;
        onExKeywordChange();
      }
    };

    const onAddKeyword = async (obj) => {
      const list = helper.prepareKeywordList(obj);
      const newList = list.map((k) => {
        return { id: k };
      });
      const result = await api.bulkCreateKeyword(newList).catch((e) => {
        notification.error({
          message: 'Error',
          description: 'Keyword add failed.' + e.response.data.message,
        });

        return false;
      });
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: result.message,
        });
        refreshComponentsFunc();
        getAccountKeyword();

        return true;
      }
      return false;
    };

    const onAddExcludeKeyword = async (obj) => {
      let isSuccess = true;
      const list = helper.prepareKeywordList(obj);
      const newList = list.map((k) => {
        return { id: k };
      });
      const result = await api.bulkCreateExcludeKeyword(newList).catch((e) => {
        notification.error({
          message: 'Error',
          description: 'Keyword add failed.' + e.response.data.message,
        });
        isSuccess = false;
      });
      if (result && result.code === 200) {
        notification.success({
          message: 'Success',
          description: result.message,
        });
        refreshComponentsFunc();
        getAccountExcludeKeyword();
      }
      return isSuccess;
    };

    const onToggleAction = (obj) => {
      const { action, record } = obj;
      if (action === 'edit') {
        // show edit modal
        Object.assign(editInput, {
          originalType: record.type,
          originalKeyword: record.originalKeyword,
          editingKeyword: record.editingKeyword,
          type: record.type,
          keyword: record.keyword,
          mode: 'keyword',
          isMultiple: false,
        });
        editVisible.value = true;
      } else if (action === 'delete') {
        Object.assign(deletingItem, {
          originalKeyword: record.originalKeyword,
          keyword: record.keyword,
          mode: 'keyword',
          isMultiple: false,
        });
        deleteVisible.value = true;
      } else if (action === 'deleteMultiple') {
        Object.assign(deletingItem, {
          keywordList: record,
          isMultiple: true,
          mode: 'keyword',
        });
        deleteVisible.value = true;
      }
    };

    const onToggleExAction = (obj) => {
      const { action, record } = obj;
      if (action === 'edit') {
        // show edit modal
        Object.assign(editInput, {
          originalType: record.type,
          originalKeyword: record.originalKeyword,
          editingKeyword: record.editingKeyword,
          type: record.type,
          keyword: record.keyword,
          mode: 'exclude',
          isMultiple: false,
        });
        editVisible.value = true;
      } else if (action === 'delete') {
        Object.assign(deletingItem, {
          originalKeyword: record.originalKeyword,
          keyword: record.keyword,
          mode: 'exclude',
          isMultiple: false,
        });
        deleteVisible.value = true;
      } else if (action === 'deleteMultiple') {
        Object.assign(deletingItem, {
          keywordList: record,
          isMultiple: true,
          mode: 'exclude',
        });
        deleteVisible.value = true;
      }
    };

    const onConfirmDelete = async () => {
      // console.log('CONFIRM', deletingItem);
      let result;
      let notiResult = {
        success: true,
        message: '',
      };
      if (deletingItem.isMultiple) {
        if (deletingItem.mode === 'keyword') {
          result = await api
            .bulkDeleteKeyword(deletingItem.keywordList)
            .catch(() => {
              notiResult.success = false;
              notiResult.message = 'Keywords remove failed.';
            });
          if (result) {
            notiResult.message = 'Keywords removed.';
            getAccountKeyword();
          }
        } else if (deletingItem.mode === 'exclude') {
          result = await api
            .bulkDeleteExcludeKeyword(deletingItem.keywordList)
            .catch(() => {
              notiResult.success = false;
              notiResult.message = 'Exclude keywords remove failed.';
            });
          if (result) {
            notiResult.message = 'Exclude keywords removed.';
            getAccountExcludeKeyword();
          }
        }
      } else {
        if (deletingItem.mode === 'keyword') {
          result = await api
            .deleteAccountKeyword(deletingItem.originalKeyword)
            .catch(() => {
              notiResult.success = false;
              notiResult.message = 'Keyword remove failed.';
            });
          if (result) {
            notiResult.message = 'Keyword removed.';
            getAccountKeyword();
          }
        } else if (deletingItem.mode === 'exclude') {
          result = await api
            .deleteAccountExcludedKeyword(deletingItem.originalKeyword)
            .catch(() => {
              notiResult.success = false;
              notiResult.message = 'Exclude keyword remove failed.';
            });
          if (result) {
            notiResult.message = 'Exclude keyword removed.';
            getAccountExcludeKeyword();
          }
        }
        if (notiResult.success) {
          notification.success({
            message: 'Success',
            description: notiResult.message,
          });
        } else {
          notification.error({
            message: 'Error',
            description: notiResult.message,
          });
        }
      }
      Object.assign(deletingItem, {
        keyword: '',
        mode: '',
        isMultiple: false,
        keywordList: [],
      });
      deleteVisible.value = false;
    };

    const confirmEdit = async () => {
      console.log('CONFIRM EDIT', editInput);
      const { originalKeyword, editingKeyword, mode, type } = editInput;

      console.log('BBEF', editInput);

      let k = editingKeyword;
      if (type === 'username') k = helper.prepareUsernameWord(editingKeyword);
      if (type === 'hashtag') k = helper.prepareHashTagWord(editingKeyword);

      const data = {
        oldWord: originalKeyword,
        newWord: k,
      };

      console.log('EDITING', data, mode);

      // update keyword
      if (mode === 'keyword') {
        const result = await api.updateAccountKeyword(data).catch(() => {});
        if (result) {
          notification.success({
            message: 'Success',
            description: 'Keyword updated.',
          });
          getAccountKeyword();
        }
      } else if (mode === 'exclude') {
        const result = await api
          .updateAccountExcludeKeyword(data)
          .catch(() => {});
        if (result) {
          notification.success({
            message: 'Success',
            description: 'Exclue keyword updated.',
          });
          getAccountExcludeKeyword();
        }
      }
      editVisible.value = false;
    };

    onMounted(() => {
      getAccountKeyword();
      getAccountExcludeKeyword();
    });

    return {
      loading,
      filterOption,
      activeKey,
      currentKeywords,
      currentKeywordList,
      currentKeywordIdList,
      currentExcludeKeywords,
      currentExcludeKeywordList,
      currentExcludeKeywordIdList,
      onAddKeyword,
      onAddExcludeKeyword,
      columnConfigSelected,
      actions,
      onToggleAction,
      onToggleExAction,
      editVisible,
      deleteVisible,
      deletingItem,
      onConfirmDelete,
      confirmEdit,
      editInput,
      onKeywordChange,
      onExKeywordChange,
      refreshComponents,
    };
  },
};
</script>

<style lang="scss" scoped>
.keyword-exclude {
  padding: 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.edit-modal-content {
  .form-row {
    .form-title {
      font-weight: 500;
      color: #272b41;
      margin-bottom: 8px;
    }

    .form-input {
      width: 100%;

      .type-selector {
        width: 100%;
        height: 48px;
      }
    }
  }
}

.edit-modal-action {
  .confirm-button,
  .cancel-button {
    border-radius: 4px;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .cancel-button {
    background: #f4f5f7;
    color: #5a5f7d;
  }
}
</style>
